<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal-form-round-trip"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Asignar Round Trip
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
              @click="limpiar()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 pt-4">
                        <div v-if="!form.id">
                          <h5>Crear datos</h5>
                        </div>
                        <div v-else>
                          <h5>Editar Datos ID:{{ form.id }}</h5>
                        </div>

                        <div class="card">
                          <div
                            class="ml-3 float-right"
                            v-if="form.sitio_cargue"
                          >
                            <button
                              type="button"
                              class="btn btn-danger"
                              @click="limpiar()"
                              title="Limpiar formulario"
                            >
                              <i class="fas fa-broom"></i>
                            </button>
                          </div>
                          <!-- Contenedor del botón y Sitio Cargue -->
                          <div
                            class="form-group col-md-12 pt-2 d-flex align-items-center"
                          >
                            <div class="flex-grow-1">
                              <label>Sitio cargue</label>
                              <v-select
                                :class="[
                                  $v.form.sitio_cargue.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                v-model="form.sitio_cargue"
                                label="nombre"
                                class="form-control form-control-sm p-0"
                                :options="this.$parent.listasForms.sitios"
                                :reduce="(site) => site.id"
                              ></v-select>
                              <div
                                class="error"
                                v-if="!$v.form.sitio_cargue.required"
                              >
                                Seleccione un sitio de cargue
                              </div>
                            </div>
                          </div>

                          <!-- Sitio Descargue -->
                          <div class="form-group col-md-12 pt-2">
                            <label>Sitio Descargue</label>
                            <v-select
                              :class="[
                                $v.form.sitio_descargue.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="form.sitio_descargue"
                              label="nombre"
                              class="form-control form-control-sm p-0"
                              :options="this.$parent.listasForms.sitios"
                              :reduce="(site) => site.id"
                            ></v-select>
                          </div>

                          <!-- Sitio cargue (Doble Ciclo) -->
                          <div class="form-group col-md-12 pt-2">
                            <label>Sitio cargue (Doble Ciclo)</label>
                            <v-select
                              :class="[
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="form.sitio_cargue2"
                              label="nombre"
                              :options="this.$parent.listasForms.sitios"
                              :reduce="(site) => site.id"
                            ></v-select>
                          </div>

                          <!-- Horas de espera -->
                          <div class="form-group col-md-12 pt-2">
                            <label>Horas de espera (maximas)</label>
                            <input
                              type="number"
                              class="form-control form-control-sm text-center"
                              v-model="form.horas"
                              maxlength="2"
                            />
                          </div>
                          <div class="form-group col-md-12 pt-2">
                            <label>Fecha Inicial</label>
                            <input
                              type="date"
                              :class="[
                                $v.form.fecha_inicial.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              class="form-control form-control-sm p-0 text-center"
                              v-model="form.fecha_inicial"
                            />
                          </div>
                          <div class="form-group col-md-12 pt-2">
                            <label>Fecha Final</label>
                            <input
                              type="date"
                              :class="[
                                $v.form.fecha_final.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              class="form-control form-control-sm p-0 text-center"
                              v-model="form.fecha_final"
                            />
                          </div>
                          <div
                            class="form-group col-md-12 pt-2 d-flex align-items-center"
                          >
                            <label class="mr-2">Aplica Loteo</label>
                            <input
                              type="checkbox"
                              v-model="form.loteo"
                              style="transform: scale(1.8); margin-left: 5px;"
                            />
                          </div>
                          <!-- Botón Guardar -->
                          <div class="modal-footer" v-show="!$v.form.$invalid">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="save()"
                            >
                              Guardar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8 col-sm-12">
                <div class="card mb-3">
                  <div class="card-body">
                    <h5>Tabla de datos</h5>
                    <table class="table table-hover text-center table-bordered table-striped table-responsive">
                      <thead class="thead-light text-center">
                        <tr>
                          <th>ID</th>
                          <th>Sitio Cargue</th>
                          <th>Sitio Descargue</th>
                          <th>Sitio Cargue (Doble Ciclo)</th>
                          <th class="col-md-1">Horas Espera (maximas)</th>
                          <th>Fecha Inicial</th>
                          <th>Fecha Final</th>
                          <th class="col-md-1">Loteo</th>
                          <th class="col-md-2">Acciones</th>
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        <tr v-for="item in datos.data" :key="item.id">
                          <td>{{ item.id }}</td>
                          <td>{{ item.sitio_cargue1.nombre }}</td>
                          <td>{{ item.sitio_descargue.nombre || "" }}</td>
                          <td v-if="item.sitio_cargue2">
                            {{ item.sitio_cargue2.nombre }}
                          </td>
                          <td v-else></td>
                          <td>{{ item.horas_espera}}</td>
                          <td>
                            {{ item.fecha_inicial }}
                          </td>
                          <td>
                            {{ item.fecha_final }}
                          </td>

                          <td
                            style="height: 100%; padding: 0; display: table-cell; vertical-align: middle; "
                          >
                            <a
                              v-if="item.loteo"
                              class="tooltip-container"
                              :title="item.loteo"
                              style="height: 100%; font-size: 13px; width: 100%;"
                            >
                              <i
                                class="fa fa-check text-success d-flex justify-content-center align-items-center"
                              ></i>
                            </a>
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              @click="edit(item.id)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-on:click="destroy(item.id)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="card-footer">
                      <div class="float-left" v-if="datos.total">
                        <p>
                          Mostrando del <b>{{ datos.from }}</b> al
                          <b>{{ datos.to }}</b> de un total:
                          <b>{{ datos.total }}</b> Registros
                        </p>
                      </div>
                      <div class="float-left" v-else>
                        <p>
                          <span class="badge badge-danger">
                            No hay registros para mostrar
                          </span>
                        </p>
                      </div>
                      <pagination
                        :data="datos"
                        @pagination-change-page="getIndex"
                        :limit="5"
                        class="text-right"
                      >
                      </pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from "laravel-vue-pagination";
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";

export default {
  name: "FaltantesRoundTrip",
  components: {
    vSelect,
    pagination,
  },
  data() {
    return {
      form: {
        id: null,
        sitio_cargue: null,
        sitio_descargue: null,
        sitio_cargue2: null,
        horas: null,
        fecha_inicial: null,
        fecha_final: null,
        loteo: false,
      },
      datos: [],
    };
  },
  validations() {
    return {
      form: {
        sitio_cargue: {
          required,
        },
        sitio_descargue: {
          required,
        },
        fecha_inicial: {
          required,
        },
        fecha_final: {
          required,
        },
      },
    };
  },

  methods: {
    getIndex(page = 1) {
      console.log("entro");

      this.$parent.cargando = true;
      axios
        .get("/api/hidrocarburos/faltantes/roundTrip?page=" + page, {})
        .then((response) => {
          this.$parent.cargando = false;
          this.datos = response.data;
        });
    },
    save() {
      let data = {
        id: this.form.id,
        sitio_cargue_id: this.form.sitio_cargue,
        sitio_cargue_ciclo_id: this.form.sitio_cargue2,
        sitio_descargue_id: this.form.sitio_descargue,
        horas_espera: this.form.horas,
        fecha_inicial: this.form.fecha_inicial,
        fecha_final: this.form.fecha_final,
        loteo: this.form.loteo ? 1 : 0,
      };

      axios({
        method: "POST",
        url: "/api/hidrocarburos/faltantes/ingreso_round_trip",
        data: data,
      })
        .then((response) => {
          this.$swal({
            title: "Éxito",
            text: `La información ha sido ${
              this.form.id ? "actualizada" : "creada"
            } correctamente.`,
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });

          this.getIndex();
          this.limpiar();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.$swal({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            console.error(error);
            this.$swal({
              title: "Error",
              text: "Hubo un problema al procesar la solicitud.",
              icon: "error",
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },

    limpiar() {
      (this.form.sitio_cargue = null),
        (this.form.sitio_cargue2 = null),
        (this.form.sitio_descargue = null),
        (this.form.horas = null),
        (this.form.id = null),
        (this.form.fecha_inicial = null),
        (this.form.fecha_final = null),
        (this.form.loteo = false);
    },
    edit(id) {
      const registro = this.datos.data.find((item) => item.id === id);

      if (!registro) {
        this.$swal({
          title: "Error",
          text: "No se encontró el registro seleccionado.",
          icon: "error",
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }

      this.form = {
        id: registro.id,
        sitio_cargue: registro.sitio_cargue1.id,
        sitio_descargue: registro.sitio_descargue.id,
        fecha_inicial: registro.fecha_inicial,
        fecha_final: registro.fecha_final,
        loteo: Boolean(registro.loteo),
      };
    },
    destroy(idRound) {
      this.$swal({
        title: "Esta seguro de eliminar esta Round Trip?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/faltantes/eliminarRound/" + idRound)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino los datos exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
  async mounted() {
    this.getIndex();
  },
};
</script>
